import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { BehaviorSubjectActivationsService } from './behavior-subject-activations.service';
import { BehaviorSubjectConfirmationService } from './behavior-subject-confirmation.service';
import { GetAuthBusinessService } from './get-auth-business.service';
import { GlobalLoaderService } from './global-loader.service';
import { RolePermissions } from './role-permissions.service';
import { RoutingService } from './routing.service';
import Cookies, { CookieAttributes }  from 'js-cookie';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  authListener = new Subject<boolean>();
  merchantId:number;
  loggedIn = false;
  _needCCAlert = new BehaviorSubject<boolean>(false);
  hasDashboardAccess = new BehaviorSubject<boolean>(false);;
  private RolePermissions:RolePermissions;
  getBusinessService: GetAuthBusinessService;
  private authCookiesKeys = {
    token: {
      name: 'toauth',
      config: {
        domain: environment.DOMAIN,
        path: ''
      } as CookieAttributes,
    },
  };
  constructor(
    private router: Router,
    public httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private BehaviorSubjectActivations: BehaviorSubjectActivationsService,
    private BehaviorSubjectConfirmation: BehaviorSubjectConfirmationService,
    public gls: GlobalLoaderService,
    private routingService: RoutingService,
    @Inject(PLATFORM_ID) private platformID
    ) {
      this.RolePermissions = new RolePermissions(httpClient,router,gls,this,platformID);
      this.getBusinessService = new GetAuthBusinessService(httpClient,router,gls,this,platformID);
    }

  async login(status: any = null, business: any = null, needsCCAlert = false, isCashApiEnabled = false, isOwner: boolean = false,merchant:number,phoneVerified:boolean, lastPasswordChangeAt = null, roleType: string, permissions: Array<any>) {
    if(!permissions?.length) return this.authListener.next(false);
    this.needCCAlert = needsCCAlert;
    this.loggedIn = true;
    Cookies.set("loggedIn", "1");
    this.setToken();
    this.authListener.next(true);
    this.BehaviorSubjectActivations.renderData.next(true);
    const url = this.getDefaultUrl(roleType, permissions);
    Cookies.set("defaultUrl", url);

    if (this.activeRoute.snapshot.queryParams.returnUrl) {
      const url = this.activeRoute.snapshot.queryParams.returnUrl;
    //  this.router.navigate([url.toString()]);
      return url.toString();
    }else if (this.getLastvistedUrl() !== null) {
     return this.getLastvistedUrl()
     // this.router.navigate([this.getLastvistedUrl()]);
    }
     else {
      if (roleType === 'Seller') {
        if (status) {
          this.setStatus(status);
        }
        if (business) {
          this.setBusiness(business,isOwner,isCashApiEnabled,merchant,phoneVerified);
        }
      }
      //this.router.navigate([url]);
      return url
    }
  }

  set needCCAlert(alert) {
    localStorage.setItem('needCCAlert', JSON.stringify(alert));
    this._needCCAlert.next(alert);
  }

  get needCCAlert() {
    if (this._needCCAlert.value) {
      return this._needCCAlert.value;
    } else {
      this._needCCAlert.next(JSON.parse(localStorage.getItem('needCCAlert')!));
      return this._needCCAlert.value;
    }
  }

  logout() {
    this.clearUserData();
    this.router.navigate(['login'], {queryParams: {message: "logged_out"}, queryParamsHandling: "merge"});
  }
  clearUserData(){
    Cookies.set(this.authCookiesKeys.token.name, "0", this.authCookiesKeys.token.config);
    this.loggedIn = false;
    this.authListener.next(false);
    this.needCCAlert = false;
    const show = localStorage.getItem("dont-show-advertisement")
    const lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem("dont-show-advertisement", show);
    localStorage.setItem("language",lang)
    sessionStorage.clear();
    this.RolePermissions.clear();
    this.getBusinessService.clear();
    Cookies.remove(this.authCookiesKeys.token.name, this.authCookiesKeys.token.config);
    Cookies.remove('loggedIn');
    Cookies.remove("defaultUrl");

  }
  userSessionExpired(){
    if(this.clearExpiredUserData()){
      this.router.navigate(['/login'],{queryParams:{sessionExpired:true},state:{isSessionExpired:true}})
    }
  }

  notAllowed(action?: string) {
    let url = '/login';
    if (action) {
      url = this.actionToUrl(action);
    }
    if (url === '/login') {
      this.clearExpiredUserData()
    }
    if(this.router.url === url) return;
    this.router.navigate([url], {
      queryParams: { sessionExpired: true },
      state: { isSessionExpired: false },
    });
  }

  static deleteAllCookies() {
    let cookies: string[] = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

  async getRole() {
    return await this.RolePermissions.getCopyRole()
  }

  private setToken(){
    const value = this.loggedIn ? 1 : 0;
    Cookies.set(this.authCookiesKeys.token.name, `${value}`, this.authCookiesKeys.token.config);
  }

   getToken() {
    try {
      return Boolean(Number(Cookies.get(this.authCookiesKeys.token.name)));
    } catch (error) {
      return false;
    }
  }


  isAuthenticated() {
    if(!this.loggedIn) return this.loggedIn = this.getToken();
    return this.loggedIn;
  }

  async isSellerOrisAdmin(roleType: string) {
    if (this.isAuthenticated()) {
      return (await this.getRole()).type == roleType ? true : false;
    } else {
      return false ;
    }
  }

  // checking activity status from the response role
  async isSellerActive() {
    const status = await this.getStatus();
    if (this.isAuthenticated() && status) {
      if (status !== 'inactive') {
        return true;
      }
    }
    return false;
  }

  setStatus(status: any) {
    this.getBusinessService.setStatus(status);
  }
  setPhoneverified(status:boolean){
    this.getBusinessService.setPhoneVerified(status);
  }

  async getStatus() {
    return this.getBusiness().then((el)=>{
      return this.getBusinessService.status
    })
  }

  async getMerchantId() {
    return this.getBusiness().then((el)=>{
      return this.getBusinessService.merchant
    })
  }
  setBusiness(business: any,isOwner?:boolean,hasCashApi?:boolean,merchant?:number,phoneVerified?:boolean) {
    this.getBusinessService.setBusiness(business,isOwner,hasCashApi,merchant,phoneVerified);
    this.BehaviorSubjectConfirmation.renderData.next(true);
  }

  async getBusiness() {
    return await this.getBusinessService.getBussinessCopy();
  }
  async hasQassatlyMerchantId() {
    let business = await this.getBusiness();
      if (business) {
        return business.QassatlyMerchant ? business.QassatlyMerchant.isEnabled : false;
      }
      return null;
  }
  async hasPermissionAction(permissionAction: string): Promise<boolean> {
    const roles = await this.getRole();
    if(!roles) return false;
    const permissions = roles.Permissions;
    for (const permission of permissions) {
      if (permission.action === permissionAction) {
        return true;
      }
    }
    return false;
  }
  async hasPermissionName(permissionName: string): Promise<boolean> {
    const roles = await this.getRole();
    if(!roles) return false;
    const permissions = roles.Permissions;
    for (const permission of permissions) {
      if (permission.name === permissionName) {
        return true;
      }
    }
    return false;
  }
  async getPermissions() {
    const roles = await this.getRole();
    const permissions = roles ? roles.Permissions : [];
    return permissions;
  }

  getAuthListener() {
    return this.authListener.asObservable();
  }

  public async hasCashAPIEnabled() {
    return this.getBusiness().then((el)=>{
      return this.getBusinessService.hasCashApi
    })
  }
  public async isOwner() {
    return this.getBusiness().then((el)=>{
      return this.getBusinessService.isOwner
    })
  }

  public async phoneverified() {
    if(this.getBusinessService.phoneVerified) return true ;
    else return this.getBusiness().then((el)=>{
      const verified = this.getBusinessService.phoneVerified
      this.getBusinessService.setPhoneVerified(verified)
      return verified
    })
  }

  public async isBusinessActiveOrVerified() {
    const business = await this.getBusiness();
    if (business) {
      return business.activeStatus === 'Active' || business.activeStatus === 'Verified';
    }
    return false;
  }
  public async isBusinessUnVerified(){
    const business = await this.getBusiness();
    if(business) return business.activeStatus === 'UnVerified';
    return false
  }

  public async hasAccessToSmsMarketing(){
    const allow = await this.hasPermissionName('see sms marketing report');
    return allow ;
  }
  public async hasAccesstoBillingSeller(){
    const allow = await this.hasPermissionName('bill payments');
    return allow ;
  }

  public async dashboardUrl() {
    const user = await this.getRole();
      if(user && user.type){
        return this.getDefaultUrl(user.type,user.Permissions);
      }
  }

  clearExpiredUserData():Boolean {
    Cookies.remove(this.authCookiesKeys.token.name);
    this.loggedIn = false;
    this.authListener.next(false);
    this.needCCAlert = false;
    const url = this.getLastvistedUrl();
    const show = localStorage.getItem('dont-show-advertisement');
    const lang = localStorage.getItem("language");
    localStorage.clear();
    localStorage.setItem('dont-show-advertisement', show);
    localStorage.setItem("language",lang)
    url ? localStorage.setItem('last-route-url', url) : '';
    sessionStorage.clear();
    this.RolePermissions.clear();
    this.getBusinessService.clear();
    return true;
  }
  getLastvistedUrl(): string {
    const route = localStorage.getItem('last-route-url');
    return this.routingService.validateRoute(route) ? route : null
  }

  getDefaultUrl(role: string, permissions?:Array<any>) {
    const hasAccess = this.hasPermissions(['dashboard permission', 'see admin dashboard'], permissions)
    if (hasAccess) this.hasDashboardAccess.next(true);
    if (hasAccess && role === 'Seller') return '/seller/dashboard';
    if (hasAccess && role === 'Admin') return '/admin/dashboard';
    else {
      return permissions[0]?.clientURL || '/';
  }}

  hasPermissions(permissionsValues: string[],permissions: Array<any>): boolean {
    return permissions.some(permission => {
      return permissionsValues.includes(permission.name)
    });
  }

  private actionToUrl(action: string): string {
    const urls = {
      ActiveSession: '/login',
      ForcePasswordChange: '/changePassword',
      phoneVerified: '/user/mobile-verification'
    };
    return urls[action];
  }
}
