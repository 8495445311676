import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from '../components/loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderService {

  private opened = false;
  private openedCount = 0;
  private dialogRef: MatDialogRef<LoaderComponent>;

  private disableService = false

  constructor(private matDialog: MatDialog) { }

  disable(){
    this.disableService = true;
  }

  enable(){
    this.disableService = false;
  }

  open() {
    if(this.disableService) return;
    if (!this.opened && this.openedCount === 0) {
      this.dialogRef = this.matDialog.open(LoaderComponent, {
        panelClass: 'global-loading-dialog',
        disableClose: true
      });
      this.opened = true;
      this.openedCount++;
    } else {
      this.openedCount++;
    }
  }

  close() {
    if (this.opened && this.openedCount > 1) {
      this.openedCount--;
    } else if (this.opened && this.openedCount === 1) {
      this.dialogRef.close();
      this.opened = false;
      this.openedCount--;
    }
  }

  closeAll(){
    for(let i = 0; i <= this.openedCount; i++){
      this.close()
    }
  }
}
